import { QueryLoader } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { transformMealHeartQuantityRule } from 'services/mealHeartService';
import { getPaymentTypes } from 'services/offerTemplateService';
import {
    getOfferTemplate,
    getOfferTemplate_offerTemplate_OfferTemplate as OfferTemplate,
} from 'types/getOfferTemplate';
import { updateClickCollectOfferTemplateVariables } from 'types/updateOfferTemplate';
import AddOrEditOfferTemplateForm, { formData } from './AddOrEditForm';
import { convertObjectToISO } from 'services/dateService';
import { FRANCE_TIMEZONE } from 'utils';

interface IPropsPanel extends RouteComponentProps<{ idHolding: string; idPos: string; idOfferTemplate: string }> {
    beforeClose: () => void;
}

const EditOfferTemplatePage = ({
    match: {
        params: { idHolding, idPos, idOfferTemplate },
    },
    history: { push },
    location: { search },
}: IPropsPanel) => {
    const onClose = () => {
        push(`${routes.cnc.offers.list(idHolding, idPos)}${search}`);
    };
    return (
        <QueryLoader
            variables={{ id: idOfferTemplate }}
            query={loader('./query/getOfferTemplate.gql')}
            fetchPolicy={'network-only'}
        >
            {({ data }: QueryResult<getOfferTemplate>) => {
                return (
                    <Mutation mutation={loader('./query/updateOfferTemplate.gql')}>
                        {(
                            updateTemplate: (param: Record<'variables', updateClickCollectOfferTemplateVariables>) => Promise<any>
                        ) => (
                            <AddOrEditOfferTemplateForm
                                isModeEdit
                                offerTemplate={data.offerTemplate as OfferTemplate}
                                idHolding={idHolding}
                                idPos={idPos}
                                onSubmit={(values: formData) => {
                                    const {
                                        name,
                                        description,
                                        fullDescription,
                                        salesType,
                                        withdrawalType,
                                        period,
                                        orderStartDefaultDaily,
                                        orderEndDefaultDaily,
                                        withdrawStartDefault,
                                        withdrawEndDefault,
                                        image,
                                        withdrawSlotDuration,
                                        minCancellationDelay,
                                        minPreparationDelay,
                                        published,
                                        translations,
                                        languages,
                                        mealHeartRule,
                                        mealHeartQuantityRule,
                                        orderPerTimeSlot,
                                        daysInAdvance,
                                        pickupPoints,
                                        hasFullDescription,
                                        hasTranslations,
                                        // payment methodes
                                        badge,
                                        cc,
                                        edenred,
                                        onsite,
                                        admission,
                                        fee,
                                        enableComment,
                                        enableWithdrawalTypeSelection,
                                        isRoomService,
                                    } = values;

                                    const validatedTranslations = translations
                                        .filter((onlineContent) => languages.includes(onlineContent.language))
                                        .map((eachTranslation) => ({
                                            ...eachTranslation,
                                            // if any of the checkboxes are unchecked we clear the text
                                            ...(!hasFullDescription && { fullDescription: '' }),
                                            ...(!hasTranslations && { onlineHelp: '' }),
                                        }));

                                    const quantityRules = mealHeartQuantityRule
                                        ? transformMealHeartQuantityRule(mealHeartQuantityRule)
                                        : [];

                                    updateTemplate({
                                        variables: {
                                            id: idOfferTemplate,
                                            name,
                                            description,
                                            fullDescription,
                                            salesType,
                                            withdrawalType,
                                            period,
                                            orderStartDefault: orderStartDefaultDaily,
                                            orderEndDefault: orderEndDefaultDaily,
                                            withdrawStartDefault,
                                            withdrawEndDefault,
                                            maxOrdersPerSlotDefault: orderPerTimeSlot.max,
                                            idImage: image.id,
                                            timezone: FRANCE_TIMEZONE,
                                            withdrawSlotDuration: withdrawSlotDuration ? convertObjectToISO(withdrawSlotDuration) : 'PT5M',
                                            minCancellationDelay: minCancellationDelay ? convertObjectToISO(minCancellationDelay) : 'PT5M',
                                            minPreparationDelay: minPreparationDelay ? convertObjectToISO(minPreparationDelay) : 'PT5M',
                                            published,
                                            translations: validatedTranslations,
                                            selectedFamilies: mealHeartRule.selectedFamilies,
                                            quantityRules,
                                            daysInAdvanceStart: daysInAdvance.min,
                                            daysInAdvanceEnd: daysInAdvance.max,
                                            paymentTypes: getPaymentTypes(badge,cc,edenred,onsite),
                                            pickupPoints,
                                            admission,
                                            fee,
                                            enableComment,
                                            enableWithdrawalTypeSelection,
                                            isRoomService,
                                        },
                                    });
                                     onClose();
                                }}
                            />
                        )}
                    </Mutation>
                );
            }}
        </QueryLoader>
    );
};

export default withRouter(EditOfferTemplatePage);
