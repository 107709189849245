import React from 'react';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Icon, ImageIconWithTooltip, Table, Loader } from 'components';

import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import routes from 'Router/routes';
import styled from 'styled-components';
import { listOfferTemplate_list_edges_node_OfferTemplate } from 'types/listOfferTemplate';
import { appTheme } from 'theme';
import { getDates } from 'services/dateService';
import { getOfferState, getPlannedAndUnavailableOffers, PlannedAndDisabledOffer } from 'services/offerService';
import { DateButton, DateButtonSize } from 'components/OfferDatesCarousel';
import { useMutation } from '@apollo/client';
import { StyledTooltip } from '../../Communication/CommunicationPage/CommunicationListTable';
import Legend from '../components/Legend';
import { OfferTemplateWithdrawalType } from 'types/globalTypes';
import { FRANCE_TIMEZONE } from 'utils';

const CREATE_OFFER_FROM_TEMPLATE_MUTATION = loader(
    '../../ClickCollect/DailyOffersPage/query/createOfferFromTemplate.gql'
);

const ICON_WIDTH = 20;
const ICON_COLOR = appTheme.color.grey[6];

const AvailableOfferTag = ({
    offer,
    onClick,
    id,
}: {
    offer: PlannedAndDisabledOffer;
    onClick: Function;
    id?: string;
}) => {
    const date = DateTime.fromISO(getDates(offer.withdrawRange)[0]).setZone(FRANCE_TIMEZONE);
    const orderRange = get(offer, 'orderRange', '');
    const isDaysInAdvance = get(offer, 'isDaysInAdvance', false);
    const isRoomService = get(offer, 'isRoomService', false);

    return (
        <DateButton
            id={id}
            onClick={() => onClick(getDates(offer.withdrawRange)[0], offer.id)}
            active={false}
            disabled={offer.disabled}
            available={offer.published}
            date={date}
            size={DateButtonSize.minimal}
            offerState={getOfferState({ orderRange, published: get(offer, 'published', false), isDaysInAdvance, isRoomService })}
            blankCard={!isDaysInAdvance && !isRoomService && offer.disabled}
        />
    );
};

const ImageToolTipContent = ({ image, offerName, offerDescription }) => {
    const { path } = image;
    return (
        <ToolTipContentContainer>
            <ToolTipImageContainer>
                <PreviewImage src={`${window.config.IMAGE_BASE_URL}/resize/141x188/${path}`} />
                <Icon.Image width={ICON_WIDTH} color={ICON_COLOR} />
            </ToolTipImageContainer>
            <>
                <ToolTipTitle>{offerName}</ToolTipTitle>
                <ToolTipDescription>{offerDescription}</ToolTipDescription>
            </>
        </ToolTipContentContainer>
    );
};

interface IPropsPanel extends RouteComponentProps<{ idHolding: string; idPos: string }> {}

const OfferTemplate = ({
    item,
    onClickEdit,
    onClick,
    index,
}: {
    item: listOfferTemplate_list_edges_node_OfferTemplate;
    onClickEdit: any;
    onClick: any;
    index: number;
}) => {
    const { t } = useTranslation();
    const { pos, name, description, withdrawalType, image, plannedOffers_v2, daysInAdvanceEnd, isRoomService } = item;

    const plannedOffers = plannedOffers_v2?.edges?.map(({ node }) => node);
    const plannedAndFutureOffers = getPlannedAndUnavailableOffers({
        plannedOffers: plannedOffers,
        fromDate: DateTime.utc().setZone(FRANCE_TIMEZONE),
        isDaysInAdvance: daysInAdvanceEnd > 0,
        isRoomService: isRoomService,
    });
    const firstOffer = plannedAndFutureOffers[0].disabled ? plannedOffers[0] : plannedAndFutureOffers[0];

    const handleOnClick = () => onClick(getDates(firstOffer.withdrawRange)[0], firstOffer.id);

    const handleWithdrawalType = () => {
        switch (withdrawalType) {
            case OfferTemplateWithdrawalType.POS_AT_SITE:
                return <Icon.ClickandSeat />;
            case OfferTemplateWithdrawalType.POS_TAKE_AWAY:
                return <Icon.ClickandCollect />;
            case OfferTemplateWithdrawalType.POS_CLICK_SERVE:
                return <Icon.SeatClickandServe />;
            case OfferTemplateWithdrawalType.CONNECTED_LOCKERS:
                return <Icon.ConnectedLocker />;
            case OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT:
                return <Icon.InstantClickandCollect />;
            case OfferTemplateWithdrawalType.TABLE_SERVICE:
                return <Icon.TableServiceIcon />;
            case OfferTemplateWithdrawalType.CLICK_AND_PICK_UP:
                return <Icon.ClickAndPickUp />;
        }
    };

    return (
        <>
            <td id={`POS-name_row-${index}`} onClick={handleOnClick} style={{ width: '17%' }}>
                <TextWrapper>{pos.name}</TextWrapper>
            </td>
            <td id={`offer-name_row-${index}`} onClick={handleOnClick} style={{ width: '25%' }}>
                <TextWrapper>{name}</TextWrapper>
            </td>
            <td id={`offer-type-icon_row-${index}`} onClick={handleOnClick} style={{ width: '8%' }}>
                {handleWithdrawalType()}
            </td>
            <td style={{ width: '48%' }}>
                <PlannedOffersContainer>
                    {plannedAndFutureOffers.map((offer, i) => (
                        <AvailableOfferTag
                            key={`dailyOfferButton-${i}_row-${index}`}
                            id={`dailyOfferButton-${i}_row-${index}`}
                            offer={offer}
                            onClick={onClick}
                        />
                    ))}
                </PlannedOffersContainer>
            </td>
            <td style={{ width: '1%' }}>
                <ActionButtonsWrapper>
                    <ImageIconWithTooltip
                        id={`imagePreview_row-${index}`}
                        iconColor={ICON_COLOR}
                        iconWidth={ICON_WIDTH}
                        image={image}
                        renderToolTipContent={
                            <ImageToolTipContent image={image} offerName={name} offerDescription={description} />
                        }
                    />
                    <StyledTooltip toolTipContent={t('page:clickcollect.offers.edit')}>
                        <span id={`edit-button_row-${index}`} onClick={onClickEdit}>
                            <Icon.Edit width={ICON_WIDTH} height={ICON_WIDTH} />
                        </span>
                    </StyledTooltip>
                </ActionButtonsWrapper>
            </td>
        </>
    );
};

interface IProps {
    data: listOfferTemplate_list_edges_node_OfferTemplate[];
    idHolding?: string;
    idPos?: string;
}

const OfferTemplateTable = ({
    data,
    history,
    location,
    idHolding,
    idPos,
}: IProps & IPropsPanel & RouteComponentProps) => {
    const { t } = useTranslation();
    const [createOfferFromTemplate, { loading }] = useMutation(CREATE_OFFER_FROM_TEMPLATE_MUTATION);

    const renderLine = (item: listOfferTemplate_list_edges_node_OfferTemplate, index: number) => {
        const onCardClick = async (offerTemplateId: string, date: string, id?: string) => {
            if (id) {
                history.push(`${routes.cnc.offers.getOffer(idHolding, item.pos.id, id)}`);
            } else {
                const res: any = await createOfferFromTemplate({
                    variables: { idOfferTemplate: offerTemplateId, forDate: date },
                });
                if (res && res.data && res.data.createOfferFromTemplate) {
                    history.push(
                        `${routes.cnc.offers.getOffer(idHolding, item.pos.id, res.data.createOfferFromTemplate.id)}`
                    );
                }
            }
        };
        return (
            <StyledTR key={`${item.id}-${index}`}>
                <OfferTemplate
                    index={index}
                    item={item}
                    onClickEdit={() => {
                        history.push(`${routes.cnc.offers.offerTemplate.edit(idHolding, item.pos.id, item.id)}`);
                    }}
                    onClick={(clickedDate, offerId) => {
                        onCardClick(item.id, clickedDate, offerId);
                    }}
                />
            </StyledTR>
        );
    };
    return loading ? (
        <Loader />
    ) : (
        <>
            <Table renderLine={renderLine} data={data} headers={getHeaders(t)} />
            <Legend />
        </>
    );
};

const getHeaders = (t: any) => [
    {
        key: 'pos.name',
        displayName: t('schema:offerTemplate.pos'),
    },
    {
        key: 'name',
        displayName: t('schema:offerTemplate.name'),
    },
    {
        key: 'withdrawalType',
        displayName: t('schema:offerTemplate.type'),
    },
    {
        key: 'name',
        displayName: t('schema:offerTemplate.nextOffersStatus'),
    },
    {
        key: 'period',
        displayName: t('schema:offerTemplate.actions'),
    },
];

const StyledTR = styled.tr`
    cursor: pointer;
`;

const TextWrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ToolTipContentContainer = styled.div`
    height: 190px;
    width: 260px;
    padding-left: ${({ theme }) => theme.spacing.xs + theme.spacing.xxs}px;
    padding-top: ${({ theme }) => theme.spacing.xs}px;
    padding-right: ${({ theme }) => theme.spacing.xs}px;
`;

const ToolTipImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const PreviewImage = styled.img`
    height: 141px;
    width: 188px;
    margin-top: ${({ theme }) => theme.spacing.xxs}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
`;

const ToolTipTitle = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xxs}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeXXS - 1}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    line-height: ${({ theme }) => theme.typography.lineHeight.XXS}px;
    letter-spacing: 0;
    color: ${({ theme }) => theme.color.common.deepGrey};
    white-space: nowrap;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ToolTipDescription = styled.div`
    margin-top: 0;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeXXS - 2}px;
    line-height: 8px;
    letter-spacing: 0;
    color: ${({ theme }) => theme.color.grey[8]};
    white-space: nowrap;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PlannedOffersContainer = styled.div`
    display: flex;
`;

export default withRouter(OfferTemplateTable);
