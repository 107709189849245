import {
    QueryLoader,
    Struct,
    Title,
    SubmitButton,
    ToggleSwitchField,
    NumberInputField,
    TimeRangeInputField,
    Icon,
} from 'components';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';

import { QueryResult } from 'localTypes';
import get from 'lodash/get';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { isRangeStartBeforeEnd } from 'services/form/offerValidator';
import {
    getBookingTemplateOffer,
    getBookingTemplateOffer_pos_Pos,
    getBookingTemplateOffer_pos_Pos_bookingOfferTemplate
} from 'types/getBookingTemplateOffer';
import { DateTime, Duration } from 'luxon';
import { getDates, getHoursAndMinutesFromStringToISO, getRangeTime } from 'services/dateService';
import { OfferTemplatePeriod } from 'types/globalTypes';
import { createBookingServiceTemplateVariables } from 'types/createBookingServiceTemplate';
import { updateBookingServiceTemplateOfferVariables } from 'types/updateBookingServiceTemplateOffer';
import isEqual from 'lodash.isequal';
import { appTheme } from 'theme';
import { FRANCE_TIMEZONE } from 'utils';

interface IProps extends RouteComponentProps<{ idPos: string }> {}

const CREATE_MUTATION = loader('../query/createBookingServiceTemplateOffer.gql');
const UPDATE_MUTATION = loader('../query/updateBookingServiceTemplateOffer.gql');
const FORMAT_DATE_DAY_MONTH: string = 'dd/LL';

const getSchema = (t: any) =>
    Yup.object().shape({
        maxSlotGuests: Yup.number().min(1, t('app:error.invalid')).required(t('app:error.required')),
        slotDuration: Yup.number().min(5, t('app:error.invalid')).required(t('app:error.required')),
        withdrawRange: Yup.string()
            .required(t('app:error.required'))
            // @ts-ignore
            .test('is start before end', t('page:bookingService.admin.timeRangeInvalid'), isRangeStartBeforeEnd),
    });

const validate = (t: any) => (values: any) => {
    let errors: any = {};
    return errors;
};

const getNextModificationsStartDate = (days: number = 0) => {
    return DateTime.local()
        .plus({ days: days + 1 })
        .toFormat(FORMAT_DATE_DAY_MONTH);
};

const AdminPage = ({
    match: {
        params: { idPos },
    },
}: IProps) => {
    const [t] = useTranslation();

    return (
        <QueryLoader
            fetchPolicy="no-cache"
            query={loader('../query/getBookingTemplateOffer.gql')}
            variables={{ idPos: idPos }}
        >
            {({ data, refetch }: QueryResult<getBookingTemplateOffer>) => {
                const pos = data.pos as getBookingTemplateOffer_pos_Pos;
                let posBookingTemplateOffer: getBookingTemplateOffer_pos_Pos_bookingOfferTemplate;

                let modificationsStartDate: string = getNextModificationsStartDate();

                if (!(pos && pos.bookingOfferTemplate.length > 0)) {
                    //default Values
                    posBookingTemplateOffer = {
                        published: false,
                        period: OfferTemplatePeriod.WEEKLY_MON,
                        maxOrdersPerSlotDefault: 30,
                        withdrawSlotDuration: 'PT15M',
                        withdrawStartDefault: 'PT07H',
                        withdrawEndDefault: 'PT13H45M',
                        daysInAdvanceStart: 0,
                        daysInAdvanceEnd: 0,
                    } as getBookingTemplateOffer_pos_Pos_bookingOfferTemplate;
                } else {
                    posBookingTemplateOffer = pos.bookingOfferTemplate[0];
                    modificationsStartDate = getNextModificationsStartDate(posBookingTemplateOffer.daysInAdvanceEnd);
                }
                return (
                    <Mutation mutation={UPDATE_MUTATION}>
                        {(
                            updateBooking: (
                                param: Record<'variables', updateBookingServiceTemplateOfferVariables>
                            ) => Promise<any>
                        ) => (
                            <Mutation mutation={CREATE_MUTATION}>
                                {(
                                    createBooking: (
                                        param: Record<'variables', createBookingServiceTemplateVariables>
                                    ) => Promise<any>
                                ) => (
                                    <Formik
                                        validate={validate(t)}
                                        validationSchema={getSchema(t)}
                                        initialValues={{
                                            active: get(posBookingTemplateOffer, 'published'),
                                            maxSlotGuests: get(posBookingTemplateOffer, 'maxOrdersPerSlotDefault', 0),
                                            slotDuration: Duration.fromISO(
                                                posBookingTemplateOffer.withdrawSlotDuration
                                            ).as('minutes'),
                                            withdrawRange: getRangeTime(
                                                posBookingTemplateOffer.withdrawStartDefault,
                                                posBookingTemplateOffer.withdrawEndDefault
                                            ),
                                            weekendAvailable:
                                                get(
                                                    posBookingTemplateOffer,
                                                    'period',
                                                    OfferTemplatePeriod.WEEKLY_MON
                                                ) === OfferTemplatePeriod.DAILY,
                                            daysInAdvanceEnd:
                                                get(posBookingTemplateOffer, 'daysInAdvanceEnd', 5) ||
                                                (get(
                                                    posBookingTemplateOffer,
                                                    'period',
                                                    OfferTemplatePeriod.WEEKLY_MON
                                                ) !== OfferTemplatePeriod.DAILY
                                                    ? 5
                                                    : 7),
                                        }}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                                            const {
                                                active,
                                                withdrawRange,
                                                maxSlotGuests,
                                                slotDuration,
                                                weekendAvailable,
                                                daysInAdvanceEnd,
                                            } = values;

                                            const [withdrawStart, withdrawEnd] = getDates(withdrawRange);

                                            //formatting values to objects for ISO
                                            let withdrawStartISO = getHoursAndMinutesFromStringToISO(withdrawStart);
                                            let withdrawEndISO = getHoursAndMinutesFromStringToISO(withdrawEnd);

                                            if (pos && pos.bookingOfferTemplate.length > 0) {
                                                try {
                                                    let bookingID = posBookingTemplateOffer.id;
                                                    await updateBooking({
                                                        variables: {
                                                            id: bookingID,
                                                            name: pos.name,
                                                            published: active,
                                                            withdrawStartDefault: withdrawStartISO,
                                                            withdrawEndDefault: withdrawEndISO,
                                                            maxOrdersPerSlotDefault: maxSlotGuests,
                                                            withdrawSlotDuration: Duration.fromObject({
                                                                minutes: slotDuration,
                                                            }).toISO(),
                                                            period: weekendAvailable
                                                                ? OfferTemplatePeriod.DAILY
                                                                : OfferTemplatePeriod.WEEKLY_MON,
                                                            orderStartDefault: 'PT0S',
                                                            orderEndDefault: withdrawEndISO,
                                                            timezone: FRANCE_TIMEZONE,
                                                            minCancellationDelay: 'PT00H01M',
                                                            daysInAdvanceStart: 0,
                                                            daysInAdvanceEnd,
                                                        },
                                                    });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                                setSubmitting(false);
                                            } else {
                                                try {
                                                    await createBooking({
                                                        variables: {
                                                            idPos,
                                                            name: pos.name,
                                                            published: active,
                                                            withdrawStartDefault: withdrawStartISO,
                                                            withdrawEndDefault: withdrawEndISO,
                                                            maxOrdersPerSlotDefault: maxSlotGuests,
                                                            withdrawSlotDuration: Duration.fromObject({
                                                                minutes: slotDuration,
                                                            }).toISO(),
                                                            period: weekendAvailable
                                                                ? OfferTemplatePeriod.DAILY
                                                                : OfferTemplatePeriod.WEEKLY_MON,
                                                            orderStartDefault: 'PT0S',
                                                            orderEndDefault: withdrawEndISO,
                                                            timezone: FRANCE_TIMEZONE,
                                                            minCancellationDelay: 'PT00H01M',
                                                            daysInAdvanceStart: 0,
                                                            daysInAdvanceEnd,
                                                        },
                                                    });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                                setSubmitting(false);
                                            }

                                            resetForm({
                                                // @ts-ignore
                                                active,
                                                withdrawRange,
                                                maxSlotGuests,
                                                slotDuration,
                                                weekendAvailable,
                                                daysInAdvanceEnd,
                                            });
                                            await refetch();
                                        }}
                                    >
                                        {({
                                            values,
                                            isSubmitting,
                                            initialValues,
                                            isValid,
                                            setFieldValue,
                                            handleChange,
                                        }) => {
                                            const changeWithdrawRange = (value: number) => {
                                                const [withdrawStart] = getDates(values.withdrawRange);
                                                const withdrawEnd = new Date(withdrawStart);
                                                withdrawEnd.setMinutes(withdrawEnd.getMinutes() + value);
                                                const withdrawStartISO =
                                                    getHoursAndMinutesFromStringToISO(withdrawStart);
                                                const withdrawEndISO = getHoursAndMinutesFromStringToISO(
                                                    withdrawEnd.toISOString()
                                                );
                                                setFieldValue('slotDuration', value);
                                                setFieldValue(
                                                    'withdrawRange',
                                                    getRangeTime(withdrawStartISO, withdrawEndISO)
                                                );
                                            };

                                            const G1 = values.daysInAdvanceEnd > initialValues.daysInAdvanceEnd;
                                            const G2 = values.daysInAdvanceEnd < initialValues.daysInAdvanceEnd;
                                            const G3 =
                                                initialValues.weekendAvailable !== values.weekendAvailable &&
                                                !initialValues.weekendAvailable;
                                            const G4 =
                                                initialValues.weekendAvailable !== values.weekendAvailable &&
                                                initialValues.weekendAvailable;
                                            const G5 = values.maxSlotGuests > initialValues.maxSlotGuests;
                                            const G6 = values.maxSlotGuests < initialValues.maxSlotGuests;
                                            const G7 = values.slotDuration > initialValues.slotDuration;
                                            const G8 = values.slotDuration < initialValues.slotDuration;

                                            const [initialWithdrawStart, initialWithdrawEnd] = getDates(
                                                initialValues.withdrawRange
                                            );
                                            const [currentWithdrawStart, currentWithdrawEnd] = getDates(
                                                values.withdrawRange
                                            );
                                            const withdrawHasChanged =
                                                new Date(initialWithdrawStart).toString() !==
                                                    new Date(currentWithdrawStart).toString() ||
                                                new Date(initialWithdrawEnd).toString() !==
                                                    new Date(currentWithdrawEnd).toString();
                                            const G9 =
                                                withdrawHasChanged &&
                                                new Date(initialWithdrawStart) >= new Date(currentWithdrawStart) &&
                                                new Date(initialWithdrawEnd) <= new Date(currentWithdrawEnd);
                                            const G10 =
                                                withdrawHasChanged &&
                                                new Date(initialWithdrawStart) <= new Date(currentWithdrawStart) &&
                                                new Date(initialWithdrawEnd) >= new Date(currentWithdrawEnd);
                                            const G11 =
                                                withdrawHasChanged &&
                                                (new Date(initialWithdrawStart) <= new Date(currentWithdrawStart) ||
                                                    new Date(initialWithdrawEnd) <= new Date(currentWithdrawEnd));
                                            const G12 =
                                                withdrawHasChanged &&
                                                (new Date(initialWithdrawStart) >= new Date(currentWithdrawStart) ||
                                                    new Date(initialWithdrawEnd) >= new Date(currentWithdrawEnd));

                                            const A = G1 || G3 || G5 || G9;
                                            const B = G2 || G4;
                                            const C = G6 || G7 || G8 || G10;
                                            const D = (A && B) || (A && C) || (B && C) || (A && B && C);

                                            let messageToDisplay;
                                            let messageType = 'default';
                                            if (D) {
                                                messageToDisplay = 'infoReducingDurationRange';
                                                messageType = 'warning';
                                            } else if (A) {
                                                messageToDisplay = 'infoChangeApplyDirectly';
                                            } else if (B) {
                                                messageToDisplay = 'infoDisableDays';
                                                messageType = 'warning';
                                            } else if (C || G11 || G12) {
                                                messageToDisplay = 'infoChangesApplyNext';
                                            }

                                            return (
                                              // @ts-ignore
                                                <Form>
                                                    <Struct.Section>
                                                        <Title
                                                            data-test={'booking-admin-title'}
                                                            grow
                                                            mode="H2"
                                                            value={t('page:bookingService.admin.title')}
                                                        >
                                                            <>
                                                                <SubmitButton
                                                                    testID='booking-admin-submit'
                                                                    displayRequiredIndication={false}
                                                                    formHasNoChange={isEqual(values, initialValues)}
                                                                    disabled={
                                                                        !isValid ||
                                                                        isSubmitting ||
                                                                        isEqual(values, initialValues)
                                                                    }
                                                                    fullWidth={false}
                                                                />
                                                            </>
                                                        </Title>
                                                        <StyledCard>
                                                            {pos &&
                                                                pos.bookingOfferTemplate.length > 0 &&
                                                                messageToDisplay && (
                                                                    <Title
                                                                        testID='booking-admin-message-display-title'
                                                                        mode="H4"
                                                                        type={messageType}
                                                                        value={
                                                                            <Trans
                                                                                i18nKey={`page:bookingService.admin.${messageToDisplay}`}
                                                                                values={{
                                                                                    date: modificationsStartDate,
                                                                                }}
                                                                                components={[<strong></strong>]}
                                                                            />
                                                                        }
                                                                        icon={
                                                                            messageType === 'warning' ? (
                                                                                <Icon.Warning
                                                                                    color={appTheme.color.common.red}
                                                                                />
                                                                            ) : (
                                                                                <Icon.Warning
                                                                                    color={appTheme.color.common.blue}
                                                                                />
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            <StyledCardContent>
                                                                <StyledColumn>
                                                                    <Field
                                                                        label={
                                                                            <Trans
                                                                                i18nKey="page:bookingService.admin.availability"
                                                                                components={[<strong></strong>]}
                                                                            />
                                                                        }
                                                                        name="active"
                                                                        component={ToggleSwitchField}
                                                                        sideLabel={true}
                                                                        onLabel={t('app:state.activeFem')}
                                                                        offLabel={t('app:state.inactiveFem')}
                                                                        testID='booking-admin-availability'
                                                                    />
                                                                    <Field
                                                                        label={
                                                                            <>
                                                                                <Trans
                                                                                    i18nKey="page:bookingService.admin.availabilityAtWeekends"
                                                                                    components={[<strong></strong>]}
                                                                                />
                                                                            </>
                                                                        }
                                                                        testID='booking-admin-availabilityAtWeekends'
                                                                        name="weekendAvailable"
                                                                        component={ToggleSwitchField}
                                                                        sideLabel={true}
                                                                        disabled={!values.active}
                                                                        onLabel={t('app:availableAtWeekends.active')}
                                                                        offLabel={t('app:availableAtWeekends.inactive')}
                                                                        handleChange={() => {
                                                                            if (
                                                                                posBookingTemplateOffer.daysInAdvanceEnd ===
                                                                                0
                                                                            )
                                                                                setFieldValue(
                                                                                    'daysInAdvanceEnd',
                                                                                    !values.weekendAvailable ? 7 : 5
                                                                                );
                                                                            setFieldValue(
                                                                                'weekendAvailable',
                                                                                !values.weekendAvailable
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Field
                                                                        label={
                                                                            <Trans
                                                                                i18nKey="page:bookingService.admin.rangeDuration"
                                                                                components={[<strong></strong>]}
                                                                            />
                                                                        }
                                                                        name="daysInAdvanceEnd"
                                                                        disabled={!values.active}
                                                                        component={NumberInputField}
                                                                        step={1}
                                                                        minValue={1}
                                                                        maxValue={30}
                                                                    />
                                                                </StyledColumn>
                                                                <StyledColumn>
                                                                    <Field
                                                                        label={
                                                                            <Trans
                                                                                i18nKey="page:bookingService.admin.maxGuestsPerSlot"
                                                                                components={[<strong></strong>]}
                                                                            />
                                                                        }
                                                                        testID='booking-admin-maxGuestsPerSlot'
                                                                        disabled={!values.active}
                                                                        name="maxSlotGuests"
                                                                        component={NumberInputField}
                                                                        minValue={1}
                                                                    />
                                                                    <Field
                                                                        label={
                                                                            <Trans
                                                                                i18nKey="page:bookingService.admin.slotDuration"
                                                                                components={[<strong></strong>]}
                                                                            />
                                                                        }
                                                                        testID='booking-admin-slotDuration-input'
                                                                        name="slotDuration"
                                                                        disabled={!values.active}
                                                                        component={NumberInputField}
                                                                        step={5}
                                                                        minValue={5}
                                                                        onChangeSlotDuration={changeWithdrawRange}
                                                                    />

                                                                    <Field
                                                                        label={
                                                                            <Trans
                                                                                i18nKey="page:bookingService.admin.bookableSlotRange"
                                                                                components={[<strong></strong>]}
                                                                            />
                                                                        }
                                                                        testID='booking-admin-bookableSlotRange-input'
                                                                        disabled={!values.active}
                                                                        name="withdrawRange"
                                                                        component={TimeRangeInputField}
                                                                        step={values.slotDuration}
                                                                    />
                                                                </StyledColumn>
                                                            </StyledCardContent>
                                                        </StyledCard>
                                                    </Struct.Section>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                )}
                            </Mutation>
                        )}
                    </Mutation>
                );
            }}
        </QueryLoader>
    );
};

// @ts-ignore
const StyledCard = styled(Struct.Card)`
    display: flex;
    flex-direction: column;
`;

const StyledCardContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledColumn = styled.div`
    flex-basis: 48%;
`;

export default withRouter(AdminPage);
