import {
    Button,
    Icon,
    LocationSearchInput,
    QueryLoader,
    SelectField,
    Struct,
    TextInputField,
    ToggleSwitchField,
    Title,
    ToggleField,
    SubmitButton,
    InputLabel,
    Address,
    NumberInputField
} from 'components';
import { ButtonType } from 'components/Button';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';

import { QueryResult } from 'localTypes';
import get from 'lodash/get';
import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
    holdingConfiguration,
    holdingConfiguration_holdingBrand_brands_brand_HoldingBrandModel,
    holdingConfiguration_holding_Holding,
} from 'types/holdingConfiguration';
import { setupHoldingCsiCashSystemVariables } from 'types/setupHoldingCsiCashSystem';
import { unsetHoldingCashSystemVariables } from 'types/unsetHoldingCashSystem';
import { updateHoldingVariables } from 'types/updateHolding';
import * as Yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import cashSystemMapper from '../../mappers/getCashSystem';
import ExternalServicesEdit from './ExternalServicesEdit';
import { ImportationTypeType } from '../../types/globalTypes';

import { isNil } from 'lodash';
interface IProps extends RouteComponentProps<{ idHolding: string }> {}

const EMPTY_CASHSYSTEM = '';
const CSI_CASHSYSTEM = '1';
const JES_CASHSYSTEM = '2';

const GET_CASH_SYSTEM = loader('./query/getCashSystem.gql');
const SETUP_EXTERNAL_CASH_SYSTEM_URL = loader('./query/setupExternalCashSystemUrl.gql');
const SETUP_SCOLAPASS_CASH_SYSTEM = loader('./query/setupScolaPassCashSystem.gql');

const getSchema = (t: any) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        code: Yup.string().required(t('app:error.required')),
        idBrand: Yup.string().required(t('app:error.required')),
        address: Yup.object().shape({
            address1: Yup.string().required(t('app:error.required')),
            address2: Yup.string(),
            city: Yup.string().required(t('app:error.required')),
            zip: Yup.string(),
            latitude: Yup.number().required(t('app:error.required')),
            longitude: Yup.number().required(t('app:error.required')),
        }),
        codeUR: Yup.string().min(1, t('app:error.required')).nullable(),
        cashSystemConfig: Yup.object().shape({
            apiKey: Yup.string(),
            code: Yup.string(),
        }),
        mercanetConfig: Yup.object().shape({
            code: Yup.string(),
            secret: Yup.string(),
            version: Yup.string(),
        }),
        edenredConfig: Yup.object().shape({
            edenredMid: Yup.string(),
        }),
        maximumPreferredZones: Yup.number().nullable()
    });

const validate = (t: any, isScolaPassCashSystem: boolean) => (values: any) => {
    let errors: any = {};
    const hasCSIConfig = values?.cashSystemInfo?.id === CSI_CASHSYSTEM;
    const hasCode = !!get(values, 'cashSystemConfig.code');
    const hasApiKey = !!get(values, 'cashSystemConfig.apiKey');
    const codeUR = get(values, 'codeUR');

    if (isScolaPassCashSystem && codeUR.length === 0) errors.codeUR = t('app:error.required');
    if (
        get(values, 'enabledPreferredZones', false) && 
        get(values, 'maximumPreferredZones', 0) < 1
      ) errors.maximumPreferredZones = t('app:error.higherThan0');

    if (!hasCSIConfig) return errors;

    if (!hasCode || !hasApiKey) errors.cashSystemConfig = {};
    if (!hasCode) errors.cashSystemConfig.code = t('app:error.required');
    if (!hasApiKey) errors.cashSystemConfig.apiKey = t('app:error.required');
    if (!!codeUR) errors.cashSystemConfig.codeUR = t('app:error.required');

    return errors;
};

const Configuration = ({
    match: {
        params: { idHolding },
    },
}: IProps) => {
    const [editable, setEditable] = useState(false);
    const [isScolaPassCashSystem, setIsScolaPassCashSystem] = useState<boolean>(false);
    const [t] = useTranslation();

    const cashSystems = cashSystemMapper(useQuery(GET_CASH_SYSTEM)?.data);

    const [setupExternalCashSystemUrl] = useMutation(SETUP_EXTERNAL_CASH_SYSTEM_URL);
    const [setupScolaPassSashSystem] = useMutation(SETUP_SCOLAPASS_CASH_SYSTEM);

    const importationTypes = React.useMemo(
        () =>
            Object.values(ImportationTypeType).map((id) => ({
                id,
                label: id,
            })),
        []
    );

    return (
        <QueryLoader
            hasData={(data: holdingConfiguration): boolean => !!(data && data.holding && data.holdingBrand)}
            query={loader('./query/holdingConfiguration.gql')}
            variables={{ id: idHolding }}
        >
            {({ data }: QueryResult<holdingConfiguration>) => {
                const holding = data.holding as holdingConfiguration_holding_Holding;
                const brands = data.holdingBrand.brands.map(
                    ({ brand }) => brand as holdingConfiguration_holdingBrand_brands_brand_HoldingBrandModel
                );
                return (
                    <Mutation mutation={loader('./query/updateHolding.gql')}>
                        {(updateHolding: (param: Record<'variables', updateHoldingVariables>) => Promise<any>) => (
                            <Mutation mutation={loader('./query/unsetHoldingCashSystem.gql')}>
                                {(
                                    unsetHoldingCashSystem: (
                                        param: Record<'variables', unsetHoldingCashSystemVariables>
                                    ) => Promise<any>
                                ) => (
                                    <Mutation mutation={loader('./query/setupHoldingCsiCashSystem.gql')}>
                                        {(
                                            setupHoldingCsiCashSystem: (
                                                param: Record<'variables', setupHoldingCsiCashSystemVariables>
                                            ) => Promise<any>
                                        ) => (
                                            <Formik
                                                key={holding.numericId}
                                                validate={validate(t, isScolaPassCashSystem)}
                                                validationSchema={getSchema(t)}
                                                initialValues={{
                                                    ...holding,
                                                    address: {
                                                        address1: get(holding, 'address.address1', ''),
                                                        address2: get(holding, 'address.address2', ''),
                                                        city: get(holding, 'address.city', ''),
                                                        zip: get(holding, 'address.zip', ''),
                                                        latitude: get(holding, 'address.latitude', 0),
                                                        longitude: get(holding, 'address.longitude', 0),
                                                    },
                                                    code: get(holding, 'code', '') as string,
                                                    importationType: get(holding, 'importationType', '') as string,
                                                    foodi: get(holding, 'foodi', false),
                                                    rating: get(holding, 'rating', false),
                                                    isRecipeDetailsEnabled: get(
                                                        holding,
                                                        'isRecipeDetailsEnabled',
                                                        false
                                                    ),
                                                    cashSystemConfig: {
                                                        apiKey: get(holding, 'cashSystemConfig.apiKey', ''),
                                                        code: get(holding, 'cashSystemConfig.code', ''),
                                                        __typename: get(holding, 'cashSystemConfig.__typename', ''),
                                                    },
                                                    idBrand: get(holding, 'brandModel.id', ''),
                                                    cashSystemInfo: {
                                                        id: get(holding, 'cashSystemInfo.id', ''),
                                                    },
                                                    externalUrl: get(holding, 'externalUrl', ''),
                                                    codeUR: get(holding, 'codeUR', ''),
                                                    isBadgeRequired: get(holding, 'isBadgeRequired'),
                                                    externalServices: holding.externalServices,
                                                    mercanetConfig: {
                                                        code: get(holding, 'mercanetConfig.code', ''),
                                                        secret: get(holding, 'mercanetConfig.secret', ''),
                                                        version: get(holding, 'mercanetConfig.version', ''),
                                                    },
                                                    edenredConfig: {
                                                        edenredMid: get(holding, 'edenredConfig.edenredMid', ''),
                                                    },
                                                    isSchool: get(holding, 'isSchool'),
                                                    isOnSitePaymentSupported: get(holding, 'isOnSitePaymentSupported'),
                                                    refillAllowed: get(holding, 'refillAllowed'),
                                                    hasAffluence: get(holding, 'hasAffluence'),
                                                    enabledPreferredZones: get(holding, 'enabledPreferredZones', false),
                                                    maximumPreferredZones: get(holding, 'maximumPreferredZones', 0)
                                                }}
                                                onSubmit={async (values, { setSubmitting }) => {
                                                    const {
                                                        address,
                                                        idBrand,
                                                        cashSystemConfig: { __typename, ...cashSystemConfigUpdates },
                                                        code,
                                                        description,
                                                        foodi,
                                                        rating,
                                                        id,
                                                        isRecipeDetailsEnabled,
                                                        name,
                                                        externalServices,
                                                        externalUrl,
                                                        codeUR,
                                                        mercanetConfig,
                                                        edenredConfig,
                                                        importationType,
                                                        isSchool,
                                                        // @ts-ignore
                                                        refillAllowed,
                                                        // @ts-ignore
                                                        hasAffluence,
                                                        isOnSitePaymentSupported,
                                                        enabledPreferredZones,
                                                        maximumPreferredZones
                                                    } = values;
                                                    const cashSystemId = values?.cashSystemInfo?.id;
                                                    const cashSystem = cashSystems?.find((c) => c?.id === cashSystemId);
                                                    const isExternalCashSystem = cashSystem?.isBadgeRequired === false;
                                                    const extUrl =
                                                        isExternalCashSystem && externalUrl === null
                                                            ? ''
                                                            : !isExternalCashSystem
                                                            ? null
                                                            : externalUrl;
                                                    const isScolaPassCashSystem = cashSystem?.isScolaPassCashSystem;
                                                    const _edenredConfig =
                                                        !!edenredConfig.edenredMid ||
                                                        !!get(holding, 'edenredConfig', null)
                                                            ? edenredConfig
                                                            : undefined;

                                                    try {
                                                        updateHolding({
                                                            variables: {
                                                                address,
                                                                id,
                                                                idBrand,
                                                                code,
                                                                description,
                                                                foodi,
                                                                rating,
                                                                isRecipeDetailsEnabled,
                                                                name,
                                                                externalServices: externalServices?.map(
                                                                    ({ id, title, content, active }) => ({
                                                                        id,
                                                                        title,
                                                                        content: content || '',
                                                                        active,
                                                                    })
                                                                ),
                                                                codeUR: isNil(codeUR) ? '' : codeUR,
                                                                externalUrl: extUrl,
                                                                mercanetConfig:
                                                                    Object.keys(mercanetConfig).length === 0
                                                                        ? undefined
                                                                        : mercanetConfig,
                                                                edenredConfig: _edenredConfig,
                                                                importationType,
                                                                isSchool,
                                                                refillAllowed,
                                                                hasAffluence,
                                                                isOnSitePaymentSupported,
                                                                enabledPreferredZones,
                                                                maximumPreferredZones: enabledPreferredZones ? maximumPreferredZones : 0
                                                            },
                                                        });
                                                        if (cashSystemId === EMPTY_CASHSYSTEM) {
                                                            await unsetHoldingCashSystem({ variables: { id } });
                                                        } else if (isScolaPassCashSystem) {
                                                            await setupScolaPassSashSystem({
                                                                variables: {
                                                                    id,
                                                                    codeUR: values?.codeUR,
                                                                    idCashSystem: Number(values.cashSystemInfo.id),
                                                                },
                                                            } as any);
                                                        } else if (isExternalCashSystem) {
                                                            await setupExternalCashSystemUrl({
                                                                variables: {
                                                                    id,
                                                                    externalUrl: extUrl,
                                                                    idCashSystem: Number(values.cashSystemInfo.id),
                                                                },
                                                            } as any);
                                                        } else if (cashSystemId === CSI_CASHSYSTEM) {
                                                            await setupHoldingCsiCashSystem({
                                                                variables: { id, ...cashSystemConfigUpdates },
                                                            });
                                                        }
                                                    } catch (error) {
                                                        console.log(error);
                                                    }
                                                    setSubmitting(false);
                                                    setEditable(false);
                                                }}
                                            >
                                                {({ setFieldValue, resetForm, values }) => {
                                                    const onAddressSelect = (address: any) => {
                                                        const { address1, address2, city, zip, latitude, longitude } =
                                                            address;
                                                        setFieldValue('address.address1', address1);
                                                        setFieldValue('address.address2', address2);
                                                        setFieldValue('address.city', city);
                                                        setFieldValue('address.zip', zip);
                                                        setFieldValue('address.latitude', latitude);
                                                        setFieldValue('address.longitude', longitude);
                                                    };
                                                    const isNone = values?.cashSystemInfo?.id === EMPTY_CASHSYSTEM;
                                                    const isJES = values?.cashSystemInfo?.id === JES_CASHSYSTEM;
                                                    const cashSystem = cashSystems?.find(
                                                        (c) => c?.id === values?.cashSystemInfo?.id
                                                    );
                                                    const isScolaPassCashSystem = cashSystem?.isScolaPassCashSystem || false;
                                                    const isExternalCashSystem = cashSystem?.isBadgeRequired === false;
                                                    setIsScolaPassCashSystem(isScolaPassCashSystem);

                                                    return (
                                                      // @ts-ignore
                                                        <Form>
                                                            <Struct.Section>
                                                                <Title grow mode="H2" value={holding.name}>
                                                                    {!editable ? (
                                                                        <Button
                                                                            display={ButtonType.ACTION}
                                                                            onClick={(e) => {
                                                                                setEditable(true);
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            {t('app:button.edit')}
                                                                        </Button>
                                                                    ) : (
                                                                        <>
                                                                            <Button
                                                                                display={ButtonType.GHOST}
                                                                                onClick={(e) => {
                                                                                    setEditable(false);
                                                                                    resetForm();
                                                                                }}
                                                                            >
                                                                                {t('app:button.cancel')}
                                                                            </Button>
                                                                            <SubmitButton fullWidth={false} />
                                                                        </>
                                                                    )}
                                                                </Title>
                                                                <StyledCard>
                                                                    <StyledColumn>
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t('page:holding.info.title.general')}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <FormLine>
                                                                            <Field
                                                                                disabled
                                                                                label={t('schema:holding.numericId')}
                                                                                name="numericId"
                                                                                component={TextInputField}
                                                                            />
                                                                            <Field
                                                                                disabled={!editable}
                                                                                label={t('schema:holding.code')}
                                                                                name="code"
                                                                                component={TextInputField}
                                                                            />
                                                                        </FormLine>
                                                                        <Field
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.name')}
                                                                            name="name"
                                                                            component={TextInputField}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.brandModel')}
                                                                            name="idBrand"
                                                                            component={SelectField}
                                                                            data={brands}
                                                                            fullWidth
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.menuType')}
                                                                            name="importationType"
                                                                            component={SelectField}
                                                                            data={importationTypes}
                                                                            fullWidth
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            name="isSchool"
                                                                            component={ToggleSwitchField}
                                                                            sideLabel={true}
                                                                            onLabel={t('schema:holding.isSchool')}
                                                                            offLabel={t('schema:holding.isSchool')}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            name="refillAllowed"
                                                                            component={ToggleSwitchField}
                                                                            sideLabel={true}
                                                                            onLabel={t('schema:holding.refillAllowed')}
                                                                            offLabel={t('schema:holding.refillAllowed')}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.paymentMethod')}
                                                                            name="isOnSitePaymentSupported"
                                                                            component={ToggleSwitchField}
                                                                            sideLabel={true}
                                                                            onLabel={t('schema:holding.isOnSitePaymentSupported')}
                                                                            offLabel={t('schema:holding.isOnSitePaymentSupported')}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.description')}
                                                                            name="description"
                                                                            component={TextInputField}
                                                                            multiline
                                                                        />
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t('page:holding.info.title.foodi')}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <Field
                                                                            inline
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.foodi')}
                                                                            name="foodi"
                                                                            component={ToggleField}
                                                                            onLabel={t('app:state.active')}
                                                                            offLabel={t('app:state.inactive')}
                                                                        />
                                                                          <Title
                                                                            mode="H3"
                                                                            value={t('page:holding.info.title.client')}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <Field
                                                                            inline
                                                                            disabled={!editable}
                                                                            label={t('schema:holding.rating')}
                                                                            name="rating"
                                                                            component={ToggleField}
                                                                            onLabel={t('app:state.active')}
                                                                            offLabel={t('app:state.inactive')}
                                                                        />
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t('page:holding.info.title.affluence')}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            name="hasAffluence"
                                                                            component={ToggleSwitchField}
                                                                            sideLabel={true}
                                                                            onLabel={t('schema:holding.hasAffluence')}
                                                                            offLabel={t('schema:holding.hasAffluence')}
                                                                        />
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t('page:holding.info.title.preferredZones')}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            name="enabledPreferredZones"
                                                                            component={ToggleSwitchField}
                                                                            sideLabel={true}
                                                                            onLabel={t('schema:holding.preferredZones')}
                                                                            offLabel={t('schema:holding.preferredZones')}
                                                                        />
                                                                        {values.enabledPreferredZones && (
                                                                          <Field
                                                                            disabled={!editable}
                                                                            label={t(
                                                                                'schema:holding.maximumPreferredZones'
                                                                            )}
                                                                            name="maximumPreferredZones"
                                                                            component={NumberInputField}
                                                                          />
                                                                        )}
                                                                    </StyledColumn>
                                                                    <StyledColumn>
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t(
                                                                                'page:holding.info.title.cash_system'
                                                                            )}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <FormLine>
                                                                            <Field
                                                                                disabled={!editable}
                                                                                fullWidth
                                                                                label={t(
                                                                                    'schema:holding.cashSystemConfig.type'
                                                                                )}
                                                                                name="cashSystemInfo.id"
                                                                                component={SelectField}
                                                                                data={cashSystems}
                                                                            />
                                                                            {isScolaPassCashSystem &&
                                                                                !isNone &&
                                                                                !isJES && (
                                                                                    <Field
                                                                                        disabled={!editable}
                                                                                        label={t(
                                                                                            'schema:holding.cashSystemConfig.codeUR'
                                                                                        )}
                                                                                        name="codeUR"
                                                                                        component={TextInputField}
                                                                                        overrideShowRequiredAsterisk
                                                                                    />
                                                                                )}
                                                                            {!isScolaPassCashSystem &&
                                                                                !isExternalCashSystem &&
                                                                                !isJES &&
                                                                                !isNone && (
                                                                                    <Field
                                                                                        disabled={!editable}
                                                                                        label={t(
                                                                                            'schema:holding.cashSystemConfig.code'
                                                                                        )}
                                                                                        name="cashSystemConfig.code"
                                                                                        component={TextInputField}
                                                                                        overrideShowRequiredAsterisk
                                                                                    />
                                                                                )}
                                                                        </FormLine>
                                                                        {!isScolaPassCashSystem &&
                                                                            !isExternalCashSystem &&
                                                                            !isNone &&
                                                                            !isJES && (
                                                                                <Field
                                                                                    disabled={!editable}
                                                                                    label={t(
                                                                                        'schema:holding.cashSystemConfig.apiKey'
                                                                                    )}
                                                                                    name="cashSystemConfig.apiKey"
                                                                                    component={TextInputField}
                                                                                />
                                                                            )}
                                                                        {!isScolaPassCashSystem &&
                                                                            isExternalCashSystem &&
                                                                            !isNone && (
                                                                                <Field
                                                                                    disabled={!editable}
                                                                                    label={t(
                                                                                        'schema:holding.cashSystemInfo.externalUrl'
                                                                                    )}
                                                                                    name="externalUrl"
                                                                                    component={TextInputField}
                                                                                />
                                                                            )}
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t('page:holding.info.title.address')}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <InputLabel
                                                                            name={t('page:holding.info.label.address')}
                                                                            showRequiredAsterisk
                                                                        />
                                                                        <StyledLocationSearchInput
                                                                            onSelect={onAddressSelect}
                                                                            disabled={!editable}
                                                                        />
                                                                        <Address
                                                                            address={values.address.address1}
                                                                            zip={values.address.zip}
                                                                            city={values.address.city}
                                                                        />
                                                                        <Field
                                                                            disabled={!editable}
                                                                            multiline
                                                                            label={t('schema:holding.address.address2')}
                                                                            name="address.address2"
                                                                            component={TextInputField}
                                                                        />
                                                                    </StyledColumn>
                                                                </StyledCard>
                                                                <StyledCard>
                                                                    <Field
                                                                        name="externalServices"
                                                                        component={ExternalServicesEdit}
                                                                        disabled={!editable}
                                                                        asRow
                                                                    />
                                                                </StyledCard>
                                                            </Struct.Section>
                                                            <Struct.Section style={{ marginTop: 16, marginBottom: 16 }}>
                                                                <StyledCard>
                                                                    <StyledRow>
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t(
                                                                                'page:holding.mercanetConfig.title'
                                                                            )}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <FlatField
                                                                            disabled={!editable}
                                                                            sideLabel={true}
                                                                            label={t(
                                                                                'page:holding.mercanetConfig.code'
                                                                            )}
                                                                            name="mercanetConfig.code"
                                                                            component={TextInputField}
                                                                        />
                                                                        <FlatField
                                                                            disabled={!editable}
                                                                            sideLabel={true}
                                                                            label={t(
                                                                                'page:holding.mercanetConfig.secret'
                                                                            )}
                                                                            name="mercanetConfig.secret"
                                                                            component={TextInputField}
                                                                        />
                                                                        <FlatField
                                                                            disabled={!editable}
                                                                            sideLabel={true}
                                                                            label={t(
                                                                                'page:holding.mercanetConfig.version'
                                                                            )}
                                                                            name="mercanetConfig.version"
                                                                            component={TextInputField}
                                                                        />
                                                                    </StyledRow>
                                                                </StyledCard>
                                                            </Struct.Section>
                                                            <Struct.Section style={{ marginTop: 16, marginBottom: 16 }}>
                                                                <StyledCard>
                                                                    <StyledRow>
                                                                        <Title
                                                                            mode="H3"
                                                                            value={t(
                                                                                'page:holding.edenredConfig.title'
                                                                            )}
                                                                            icon={<Icon.Cog />}
                                                                        />
                                                                        <FlatField
                                                                            disabled={!editable}
                                                                            sideLabel={true}
                                                                            label={t(
                                                                                'page:holding.edenredConfig.edenredMid'
                                                                            )}
                                                                            name="edenredConfig.edenredMid"
                                                                            component={TextInputField}
                                                                        />
                                                                    </StyledRow>
                                                                </StyledCard>
                                                            </Struct.Section>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        )}
                                    </Mutation>
                                )}
                            </Mutation>
                        )}
                    </Mutation>
                );
            }}
        </QueryLoader>
    );
};

const StyledLocationSearchInput = styled(LocationSearchInput)`
    margin: ${({ theme }) => theme.spacing.s}px 0;
`;

const FlatField = styled(Field)`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > label {
        width: 35%;
        margin-bottom: 0;
    }

    & > input {
        max-width: 60%;
        text-align: center;
    }
`;

// @ts-ignore
const StyledCard = styled(Struct.Card)`
    display: flex;
    justify-content: space-between;
`;

const StyledColumn = styled.div`
    flex-basis: 48%;
`;

const StyledRow = styled.div`
    flex-basis: 96%;
`;

const FormLine = styled.div`
    display: flex;
    justify-content: space-between;
    & > * {
        flex-basis: 48%;
    }
`;

export default withRouter(Configuration);
