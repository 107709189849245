import React, { useState } from 'react';
import { DayPicker, TextInput, Button, Row, Column, Icon, Text } from 'components';
import styled from 'styled-components';
import { holdingPos_pos_Pos_schedules } from 'types/holdingPos';
import { ButtonType } from 'components/Button';
import { useTranslation } from 'react-i18next';
import { appTheme } from 'theme';

const getAllInactiveSchedulesDays = (schedules: holdingPos_pos_Pos_schedules[], index?: number): number[] => {
    const days = new Set(
        schedules.filter((_, idx) => idx !== index).reduce((days, schedule) => days.concat(schedule.days as []), [])
    );
    return Array.from(days);
};
interface IDaysEditProps {
    schedules: holdingPos_pos_Pos_schedules[];
    index: number;
    onChange: (schedules: holdingPos_pos_Pos_schedules[]) => void;
    onAdd: (e: React.MouseEvent) => void;
}
const DaysEdit = ({ schedules, index, onChange, onAdd }: IDaysEditProps) => {
    const onRemove = (index: number) => {
        // @ts-ignore
        onChange([].concat(schedules.slice(0, index), schedules.slice(index + 1)));
    };
    return (
        <DayWrapper>
            <DayShrink
                dayValues={[1, 2, 3, 4, 5, 6, 7]}
                multiSelect={true}
                onChange={(days: number[]) => {
                    const nSchedules = [...schedules];
                    nSchedules[index].days = days;
                    onChange(nSchedules);
                }}
                inactiveDays={getAllInactiveSchedulesDays(schedules, index)}
                activeDays={schedules[index].days}
            >
                {''}
            </DayShrink>
            {index === 0 && (
                <Button
                    type="button"
                    display={ButtonType.GHOST}
                    onClick={onAdd}
                    inline
                    disabled={getAllInactiveSchedulesDays(schedules).length === 8}
                >
                    +
                </Button>
            )}
            {index !== 0 && (
                <Delete onClick={() => onRemove(index)}>
                    <Icon.Bin />
                </Delete>
            )}
        </DayWrapper>
    );
};
interface IHourTextInputProps {
    value: string;
    dayIndex: number;
    hourIndex: number;
    schedules: holdingPos_pos_Pos_schedules[];
    onChange: (schedules: holdingPos_pos_Pos_schedules[]) => void;
}
const HourTextInput = ({ value, onChange, schedules, hourIndex, dayIndex }: IHourTextInputProps) => {
    const [inputValue, setInputValue] = useState(value);
    const setFieldOnChange = (v: string) => {
        if (v.match(/^[0-9]{0,2}:[0-9]{0,2}$/)) {
            setInputValue(v);
        }
    };

    const onBlur = () => {
        const nSchedules = JSON.parse(JSON.stringify(schedules));
        nSchedules[dayIndex].hours[hourIndex] = inputValue.length < 6 ? `${inputValue}:00` : inputValue;
        onChange(nSchedules);
    };
    return <HourInput value={inputValue} onChange={setFieldOnChange} onBlur={onBlur}/>;
};
interface IHoursEditProps {
    hours: any[];
    dayIndex: number;
    schedules: holdingPos_pos_Pos_schedules[];
    onChange: (schedules: holdingPos_pos_Pos_schedules[]) => void;
}

const HoursEdit = ({ schedules, hours, dayIndex, onChange }: IHoursEditProps) => {
    const onRemove = (dayIndex: number, hourIndex: number) => {
        const nSchedules = [...schedules];
        const newHours: {[key: number]: string}[] = [...nSchedules[dayIndex].hours];

        // @ts-ignore
        nSchedules[dayIndex].hours = newHours.toSpliced(hourIndex - 1, 2);
        onChange(nSchedules);
    };
    const addTimeToString = (hour: string, step = 1): string => {
        if (!hour || hour.length < 5) return `12:00:00`;
        const times = hour.split(':');
        times[0] = `${parseInt(times[0], 10) + step}`;
        return times.join(':');
    };
    const onAdd = (dayIndex: number) => {
        const nSchedules = [...schedules];
        const hours = schedules[dayIndex].hours;
        const scheduleHour = addTimeToString(hours[hours.length - 1]);
        nSchedules[dayIndex].hours = hours.concat([scheduleHour, addTimeToString(scheduleHour)]);
        onChange(nSchedules);
    };
    const [t] = useTranslation();

    return (
        <Column justify="flex-start">
            <Row justify="space-around" wrapped>
                {hours.map((hour, hourIndex) => (
                    <React.Fragment key={`${hour}`}>
                        <HourTextInput
                            schedules={schedules}
                            onChange={onChange}
                            dayIndex={dayIndex}
                            hourIndex={hourIndex}
                            value={hour.length > 7 ? hour.substr(0, hour.length - 3) : hour}
                        />
                        {hourIndex % 2 === 1 && (
                            <Delete onClick={() => onRemove(dayIndex, hourIndex)}>
                                <Icon.Bin />
                            </Delete>
                        )}
                    </React.Fragment>
                ))}
            </Row>
            <Row justify="flex-start">
                <AddHours onClick={() => onAdd(dayIndex)}>
                    <Text bold color={appTheme.color.common.blue}>
                        {t('component:schedule.addHours')}
                    </Text>
                </AddHours>
            </Row>
        </Column>
    );
};

interface IProps {
    onChange: (schedules: holdingPos_pos_Pos_schedules[]) => void;
    schedules: holdingPos_pos_Pos_schedules[];
}

const ScheduleEdit = ({ schedules, onChange: propsOnChange }: IProps) => {
    const onChange = (schedules: holdingPos_pos_Pos_schedules[]) => {

        let newSchedules = [...schedules];
        newSchedules.map(schedule => {
          const days = [...schedule.days];
          const hours = [...schedule.hours];
          return {
              ...schedule,
              days: days.sort((a, b) => a - b),
              newHours: hours.sort()
        }});
        propsOnChange(newSchedules);
    };
    const onAdd = (e: React.MouseEvent) => {
        const nSchedules: holdingPos_pos_Pos_schedules[] = [
            ...schedules,
            { days: [], hours: ['12:00:00', '14:00:00'], __typename: 'OpeningHour' },
        ];
        propsOnChange(nSchedules);
    };
    return (
        <Wrapper>
            {schedules.map((schedule, i) => (
                <ScheduleWrapper key={String(i)}>
                    <DaysEdit schedules={schedules} index={i} onChange={onChange} onAdd={onAdd} />
                    <HoursEdit schedules={schedules} hours={schedule.hours} dayIndex={i} onChange={onChange} />
                </ScheduleWrapper>
            ))}
            {schedules.length === 0 && (
                <ScheduleWrapper>
                    <Button
                        type="button"
                        display={ButtonType.GHOST}
                        onClick={onAdd}
                        inline
                        disabled={getAllInactiveSchedulesDays(schedules).length === 8}
                    >
                        +
                    </Button>
                </ScheduleWrapper>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;
const DayWrapper = styled.div`
    display: flex;
    flex-wrap: no-wrap;
`;

const AddHours = styled.a`
    padding-left: 20px;
    cursor: pointer;
`;

const DayShrink = styled(DayPicker)`
    width: auto;
    padding: 0;
`;

const HourInput = styled(TextInput)`
    padding-bottom: ${({ theme }) => theme.spacing.s - 12}px;
    width: auto;
`;

const Delete = styled.a`
    padding: ${({ theme }) => theme.spacing.xs}px;
    cursor: pointer;
`;

const ScheduleWrapper = styled(Column)`
    margin: ${({ theme }) => theme.spacing.xs}px 0 ${({ theme }) => theme.spacing.xs}px 0;
    padding: ${({ theme }) => theme.spacing.xs}px 0 ${({ theme }) => theme.spacing.xs}px 0;
    border: 1px solid ${({ theme }) => theme.color.grey[0]};
    border-radius: ${({ theme }) => theme.borderRadius.xs}px;
    width: 429px;
`;
export default ScheduleEdit;
