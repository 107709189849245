import { Duration, DateTime } from 'luxon';
import { getDates, getDateTime } from 'services/dateService';
import { FRANCE_TIMEZONE } from 'utils';

export function isRangeStartBeforeEnd(range: string) {
    const [startDate, endDate] = getDates(range);
    return new Date(startDate).getTime() < new Date(endDate).getTime();
}

export function isDurationRangeStartBeforeEnd(duration: string) {
    const [startDuration, endDuration] = getDates(duration);

    return (
        new Date(getDateTime(Duration.fromISO(startDuration)).toISO()).getTime() <
        new Date(getDateTime(Duration.fromISO(endDuration)).toISO()).getTime()
    );
}

export const isDurationStartBeforeEnd = (start: string, end: string) =>
  new Date(getDateTime(Duration.fromISO(start)).toISO()).getTime() <= new Date(getDateTime(Duration.fromISO(end)).toISO()).getTime();

export function areRangesValid(orderRange: string, withdrawRange: string, slotDuration: number) {
    const orderEndDate = new Date(getDates(orderRange)[1]);
    const withdrawRangeEndDate = new Date(getDates(withdrawRange)[1]);
    withdrawRangeEndDate.setMinutes(withdrawRangeEndDate.getMinutes() - slotDuration);
    return withdrawRangeEndDate.getTime() >= orderEndDate.getTime();
}

export function isBookingWithdrawRangeValid(withdrawRange: string, initialWithdrawRange: string) {
    const [startDate, endDate] = getDates(withdrawRange);
    const [initialStartDate, initialEndDate] = getDates(initialWithdrawRange);
    return (
        new Date(startDate).getTime() <= new Date(initialStartDate).getTime() &&
        new Date(endDate).getTime() >= new Date(initialEndDate).getTime()
    );
}

export const isEndDateValid = (timeRangeToCheck: string, dayToCheck: string) => {
  const dayToCheckMidnight = DateTime.fromISO(dayToCheck).setZone(FRANCE_TIMEZONE).endOf('day');
  const timeRangeEndDate = DateTime.fromISO(getDates(timeRangeToCheck)[1]).setZone(FRANCE_TIMEZONE);

  return dayToCheckMidnight >= timeRangeEndDate;
}
